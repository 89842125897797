<template>
  <list-condition-template ref="table" :hasAside="false"
  indexColumn
  :rows="rows"
  :total="total"
  v-model:listQuery="listQuery"
  @page-info-change="handlePageInfoChange"
  @selected="onMultiSelected">
    <template #condition>
      <el-form-item>
        <el-input placeholder="姓名" v-model="listQuery.condition.userName" clearable></el-input>
      </el-form-item>
      <el-form-item>
        <el-input placeholder="账号" v-model="listQuery.condition.account" clearable></el-input>
      </el-form-item>
      <el-form-item>
        <el-select placeholder="功能模块" @change="handleFilter" v-model="listQuery.condition.module" clearable>
            <el-option
                v-for="(item, index) in modules"
                :key="index"
                :label="item"
                :value="item">
            </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-date-picker v-model="dateArray" type="datetimerange" :picker-options="pickerOptions2" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" align="center" value-format="YYYY-MM-DD HH:mm:ss" @change="changeTime">
        </el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-button class="filter-item" style="margin-left: 10px;" type="primary" v-waves icon="el-icon-search" @click="handleFilter">搜索</el-button>
        <!-- <el-button class="filter-item" style="margin-left: 10px;" v-waves @click="cancel" type="info">清除</el-button> -->
      </el-form-item>
    </template>
    <!-- <template slot="normalOperation">
        <el-form-item class="normal-operation">
          <el-button class="filter-item" type="primary" v-waves icon="el-icon-download" @click="handleImport">导出日志</el-button>
        </el-form-item>
      </template> -->

    <template #columns>
      <el-table-column label="姓名" align="center" property="userName"></el-table-column>
      <el-table-column label="账号" align="center" property="account"></el-table-column>
      <el-table-column label="操作内容" min-width="100" align="center" property="operation"></el-table-column>
      <el-table-column label="功能模块" min-width="100" align="center" property="module"></el-table-column>
      <el-table-column label="客体ID" min-width="100" align="center" property="entityId"></el-table-column>
      <!-- <el-table-column label="失败原因" min-width="100" align="center" property="cause" show-overflow-tooltip></el-table-column> -->
      <el-table-column label="IP地址" min-width="100" align="center" property="ipAddress"></el-table-column>
      <el-table-column label="URL" min-width="100" align="center" property="uri"></el-table-column>
      <el-table-column label="操作时间" min-width="150" align="center" sortable="custom" property="time"></el-table-column>
      <el-table-column label="是否成功" min-width="100" align="center" property="isSuccess">
        <template #default="scope">
           <el-tag :type="showFilter(scope.row.isSuccess)">{{scope.row.isSuccess==='YES'?'成功':'失败'}}</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="message" width="150" align="center" property="message" show-overflow-tooltip>
        <template #default="scope">
          {{scope.row.message }}{{scope.row.cause?(': ' + scope.row.cause):''}}
        </template>
      </el-table-column>
      <!-- <el-table-column align="center" label="日志详情" class-name="fixed-width">
          <template slot-scope="scope">
            <el-button size="mini" type="info" @click="handleCreate(scope.row)" v-waves>查看</el-button>
          </template>
        </el-table-column> -->
    </template>

    <!-- <el-dialog :title="newDevice.title" :visible.sync="newDevice.show" width="60%" @close="handleNewDeviceDialogClose">
        <el-form size="mini" ref="newDeviceForm" :model="newDevice.formData" :rules="newDevice.rules" label-width="80px" status-icon>
        <el-table :data="tableData" border style="width: 100%">
          <el-table-column prop="role" label="账号">
          </el-table-column>
          <el-table-column prop="account" label="角色">
          </el-table-column>
          <el-table-column prop="param" label="操作内容" >
          </el-table-column>
          <el-table-column prop="time" label="操作时间">
          </el-table-column>
        </el-table>
        </el-form>
      </el-dialog> -->
  </list-condition-template>
</template>

<script>
import operationApi from '@/apis/operationLog'
import waves from '@/directive/waves' // 水波纹指令
import ListConditionTemplate from '@/components/templates/listConditionTemplate'
import dayjs from 'dayjs'
// import filterDialog from '@/components/filterDialog/filterDialog'
// import SingleSourceFilterDialog from '@/components/filterDialog/singleSourceFilterDialog'
export default {
  directives: {
    waves
  },
  components: {
    ListConditionTemplate
  },
  data () {
    return {
      pickerOptions2: {
        shortcuts: [
          {
            text: '最近一周',
            onClick (picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(dayjs().subtract(1, 'week'))
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近一个月',
            onClick (picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(dayjs().subtract(1, 'month'))
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近三个月',
            onClick (picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(dayjs().subtract(3, 'month'))
              picker.$emit('pick', [start, end])
            }
          }
        ]
      },
      dateArray: '',
      tableData: [],
      modules: [],
      rows: null,
      total: 0,
      listQuery: {
          condition:{
            userName: undefined,
            account: undefined,
            operationType: undefined,
            startTime: null,
            endTime: null
          }
        ,
        pageInfo:{
          pageIndex: 1,
          pageSize: 20,
          orders: ['time', 'DESC']
        }
      },
      locations: [],
      newDevice: {
        formData: {
          version: '',
          productDate: '',
          fixDate: '',
          type: '',
          sn: '',
          building: '',
          floor: '',
          room: '',
          location: []
        },
        other: false,
        title: '日志详情',
        show: false,
        loading: false,
        showModelSelectionDialog: false
      }
    }
  },

  created () {
    this.getModules()
    this.getList()
  },
  methods: {
          /**
     * 表格行显示状态过滤
     * @param {*} show 显示状态
     */
    showFilter (show) {
      return show === 'YES' ? 'success' : 'danger'
    },
    /**
     * 加载数据
     */
    getList (pageInfo) {
      operationApi.getPaged(this.listQuery).then(response => {
        this.rows = response.data.content
        this.total = response.data.totalElements
      })
    },

    /**
     * 获取操作类型
     */
    getModules () {
      operationApi.getModules().then(res => {
        this.modules = res.data
      })
    },
    /**
     * 搜索条件变更处理
     */
    handleFilter () {
      this.getList()
    },
    /**
     * 分页信息变动事件处理
     * @param  {Object} pageInfo 分页信息
     */
    handlePageInfoChange (pageInfo) {
      this.getList()
    },
    changeTime () {
      if (Array.isArray(this.dateArray)) {
        [this.listQuery.condition.startTime, this.listQuery.condition.endTime] = this.dateArray
      } else {
        this.listQuery.condition.startTime = null
        this.listQuery.condition.endTime = null
      }
      this.handleFilter()
    },

    cancel () {
      this.listQuery.condition.userName = ''
      this.listQuery.condition.operationTime = ''
      this.listQuery.condition.account = ''
    },
    downloadTemplate () {
      // 下载导入模板
    },
  }
}
</script>
