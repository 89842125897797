import api from './api'

export class OperationLogApi extends api {
  getModules () {
    return this.request({
      url: `${this.servicePath}/${this.modulePath}/getModules`,
      method: 'post'
    })
  }
}

export default new OperationLogApi('operationLog')
